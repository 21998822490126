import gql from 'graphql-tag'

export const Sites = gql`
  query sites($projectId: ID!) {
    sites(projectId: $projectId) {
      id
      siteId
    }
  }
`

export const Site = gql`
  query site($id: ID!) {
    site(id: $id) {
      id
      siteId
      name
      address
      height
      lng
      lat
      region
      chief
      neighbourhood
      structureType
      obs
      towerOwner
      structureCode
    }
  }
`
