<template>
  <v-container
    class="px-4"
    style="width: 1400px"
    fluid
    >
    <v-card
      rounded="lg"
      elevation="3"
      >
      <v-card-title
        class="align-center"
        style="gap: 12px"
        >
        Repositorio
        <v-spacer></v-spacer>

        <v-autocomplete
          outlined
          flat
          dense
          hide-details
          clearable
          class="rounded-lg ml-auto"
          style="max-width: 250px"
          label="Buscar sitio"
          prepend-inner-icon="mdi-magnify"
          :items="sites"
          item-text="siteId"
          item-value="id"
          v-model="siteId"
          ></v-autocomplete>

        <create-folder
          @reload="reload"
          :siteId="siteId"
          ></create-folder>
      </v-card-title>
      <v-card-text>
        <repository 
          ref="list"
          :siteId="siteId"
          :key="siteId"
          ></repository>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Sites } from '@/graphql/queries/sites'

const Repository = () => import('@/components/repository/List')
const CreateFolder = () => import('@/components/repository/CreateFolder')

export default {
  data: () => ({
    sites: [],
    siteId: null
  }),

  created () {
    this.fetchSites ()
  },

  methods: {
    reload () {
      this.$nextTick( () => {
        this.$refs.list.fetchFolders()
      })
    },

    fetchSites () {
      this.$apollo.query({
        query: Sites,
        variables: {
          projectId: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.sites = res.data.sites
      })
    },
  },

  components: {
    Repository,
    CreateFolder
  },
}
</script>
